import React from "react";
import "./App.css";
import FeatureSection from "./components/FeatureSection";
import HeroSection from "./components/HeroSection";
import Header from "./components/Header";
import reportImage from "./assets/images/feature-report.png";
import consumptionImage from "./assets/images/feature-consumption.png";
import docsImage from "./assets/images/feature-docs.png";
import updatesImage from "./assets/images/feature-updates.png";

function App() {
  return (
    <>
      <div className="relative overflow-hidden">
        {/* Top Circle Background */}
        <div className="absolute top-0 right-0 w-[300px] h-[300px] sm:w-[450px] sm:h-[450px] md:w-[600px] md:h-[600px] bg-custom-pink rounded-full transform translate-x-1/3 -translate-y-1/3 z-0 lg:right-[calc((100vw-1024px)/2+10rem)]"></div>

        <div className="max-w-screen-lg mx-auto">
          {/* Header */}
          <Header />

          {/* Hero Section */}
          <HeroSection />

          {/* Feature Sections */}
          <FeatureSection
            title="Se din förbrukning i realtid"
            description="Håll koll på din el- och vattenförbrukning enkelt. Vår app ger tydlig, realtidsdata som hjälper dig att hantera dina hushållsresurser smidigt och effektivt."
            imageSrc={consumptionImage}
            bgColor="bg-custom-blue"
            imageOnLeft={true}
          />

          <FeatureSection
            title="Enkelt & smidigt att anmäla fel i din bostad"
            description="Få hjälp snabbt genom att anmäla fel i din bostad direkt från appen. Ange vilket rum som har problem, och vi tar hand om resten."
            imageSrc={reportImage}
            bgColor="bg-white"
            imageOnLeft={false}
          />

          <FeatureSection
            title="Få alla uppdateringar från din hyresvärd"
            description="Få viktiga meddelanden och uppdateringar från din hyresvärd direkt i appen. Håll dig informerad om allt som rör din bostad."
            imageSrc={updatesImage}
            bgColor="bg-custom-purple"
            imageOnLeft={true}
          />

          <FeatureSection
            title="Alla relevanta dokument i bopärmen"
            description="Få snabb åtkomst till alla relevanta dokument om din bostad direkt i appen. Från skötselråd till avtal, allt är samlat på ett ställe."
            imageSrc={docsImage}
            bgColor="bg-white"
            imageOnLeft={false}
          />

          {/* Contact Section */}
          <section id="contact" className="py-8 relative z-30">
            <div className="container mx-auto px-4 text-center">
              <div className="border-t border-gray-300 pt-8">
                <div className="space-y-4">
                  <div>
                    <p className="font-semibold">Snapp Home</p>
                    <p>Arenagatan 14</p>
                    <p>215 33, Malmö</p>
                  </div>
                  <div>
                    <a
                      href="mailto:admin@snapp-home.se"
                      className="text-blue-600 hover:underline"
                    >
                      admin@snapp-home.se
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>

        {/* Bottom Circle Background */}
        <div className="absolute -bottom-40 left-8 w-[350px] h-[350px] sm:w-[450px] sm:h-[450px] bg-custom-pink rounded-full transform -translate-x-1/3 translate-y-1/3 z-20 lg:left-[calc((100vw-1024px)/2+6rem)]"></div>
      </div>
    </>
  );
}

export default App;
