import React from 'react';

function FeatureSection({ title, description, imageSrc, bgColor, imageOnLeft = false }) {
  const contentOrder = imageOnLeft ? 'flex-row-reverse' : 'flex-row';
  const imageAlignment = imageOnLeft ? 'justify-start' : 'justify-end';

  return (
    <section className="relative">
      <div className={`${bgColor} relative z-10 py-8 md:py-12 lg:py-16 lg:rounded-3xl`}>
        <div className={`container mx-auto flex ${contentOrder} items-center justify-between px-4 sm:px-6 md:px-8`}>
          {/* Text Content */}
          <div className={`text-left w-3/5 md:w-2/3 ${imageOnLeft ? 'pr-4 md:pr-8' : 'pl-4 md:pl-8'} lg:-mt-24`}>
            <h2 className="text-xl md:text-2xl font-bold mb-2 font-murecho max-w-md">{title}</h2>
            <p className="text-sm md:text-base font-montserrat max-w-md">{description}</p>
          </div>
          
          {/* Image */}
          <div className={`w-2/5 md:w-1/3 ${imageOnLeft ? 'pl-4 md:pl-8' : 'pr-4 md:pr-8'} flex ${imageAlignment}`}>
            <img src={imageSrc} alt={title} className="w-3/4 md:w-2/3 py-4 md:py-6 lg:-mt-40" />
          </div>
        </div>
      </div>
    </section>
  );
}

export default FeatureSection;