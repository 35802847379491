import React, { useState } from 'react';
import logoImg from '../assets/images/logo.png';

const Header = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <header className={`relative z-40 transition-colors duration-300 ${isMenuOpen ? 'bg-white' : 'bg-transparent'}`}>
      <div className="container mx-auto px-4 py-4 flex justify-between items-center">
        <div className="h-8">
          <img src={logoImg} alt="Snapp Logo" className="h-full" />
        </div>
        <nav className="sm:hidden">
          <button onClick={toggleMenu} className="text-black focus:outline-none">
            {isMenuOpen ? (
              <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
              </svg>
            ) : (
              <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 6h16M4 12h16M4 18h16" />
              </svg>
            )}
          </button>
        </nav>
        <nav className={`sm:hidden absolute top-full left-0 right-0 bg-white shadow-md transition-all duration-300 ${isMenuOpen ? 'opacity-100 visible' : 'opacity-0 invisible h-0 overflow-hidden'}`}>
          <ul className="flex flex-col p-4">
            <li className="mb-4 border-b pb-2">
              <button 
                onClick={() => {
                    setIsMenuOpen(false); // Close the menu after clicking
                  const contactSection = document.getElementById('contact');
                  if (contactSection) {
                    const yOffset = -60; // Adjust this value to leave some space at the top
                    const y = contactSection.getBoundingClientRect().top + window.pageYOffset + yOffset;
                    window.scrollTo({top: y, behavior: 'smooth'});
                  }
                }}
                className="text-black py-2 px-4 hover:text-gray-700 transition duration-300 font-montserrat w-full text-left"
              >
                Kontakta oss
              </button>
            </li>
            <li className="mb-4 border-b pb-2">
              <a href="https://portal.snapp-home.se/privacy-policy" target="_blank" rel="noopener noreferrer" className="text-black py-2 px-4 hover:text-gray-700 transition duration-300 font-montserrat w-full text-left block">
                Privacy Policy
              </a>
            </li>
            <li className="mb-4 border-b pb-2">
              <a href="https://www.apple.com/legal/internet-services/itunes/dev/stdeula" target="_blank" rel="noopener noreferrer" className="text-black py-2 px-4 hover:text-gray-700 transition duration-300 font-montserrat w-full text-left block">
                EULA
              </a>
            </li>
            <li className="mb-4 border-b pb-2">
              <a href="https://apps.apple.com/se/app/snapp-boendeapp/id6680190341" target="_blank" rel="noopener noreferrer" className="text-black py-2 px-4 hover:text-gray-700 transition duration-300 font-montserrat w-full text-left block">
                Ladda ner för Apple
              </a>
            </li>
            <li>
              <a href="https://play.google.com/store/apps/details?id=com.snapphome.app" target="_blank" rel="noopener noreferrer" className="text-black py-2 px-4 hover:text-gray-700 transition duration-300 font-montserrat w-full text-left block">
                Ladda ner för Android
              </a>
            </li>
          </ul>
        </nav>
        <nav className="hidden sm:block">
          <ul className="flex space-x-6">
            <li>
              <a href="https://portal.snapp-home.se/privacy-policy" target="_blank" rel="noopener noreferrer" className="text-black hover:text-gray-700 transition duration-300 font-montserrat">
                Privacy Policy
              </a>
            </li>
            <li>
              <a href="https://www.apple.com/legal/internet-services/itunes/dev/stdeula/" target="_blank" rel="noopener noreferrer" className="text-black hover:text-gray-700 transition duration-300 font-montserrat">
                EULA
              </a>
            </li>
            <li>
              <button 
                onClick={() => {
                  const contactSection = document.getElementById('contact');
                  if (contactSection) {
                    const yOffset = -60;
                    const y = contactSection.getBoundingClientRect().top + window.pageYOffset + yOffset;
                    window.scrollTo({top: y, behavior: 'smooth'});
                  }
                }}
                className="text-black hover:text-gray-700 transition duration-300 font-montserrat"
              >
                Kontakta oss
              </button>
            </li>
          </ul>
        </nav>
      </div>
    </header>
  );
};

export default Header;