import React from 'react';
import heroImage from '../assets/images/hero-image.png';
import appstoreImg from '../assets/images/appstore.png';
import androidImg from '../assets/images/android.png';
import { ReactComponent as UnderlineSvg } from '../assets/images/underline.svg'; // Import the SVG as a React component

const HeroSection = () => {
  return (
    <section className="hero bg-transparent text-black sm:py-10 py-4 relative overflow-hidden lg:mb-20">
      {/* Content */}
      <div className="container mx-auto px-4 relative z-20">
        <div className="flex flex-col sm:flex-row items-center justify-between">
          <div className="w-full sm:w-2/3 mb-8 sm:mb-0 relative">
            <h1 className="text-4xl md:text-5xl font-bold mb-8 font-murecho text-center sm:text-left">
              Välkommen till<br />din nya <span className="relative inline-block">bostadsapp
                <span className="absolute left-0 bottom-0 w-full h-auto -mb-4 md:-mb-6">
                  <UnderlineSvg className="w-full h-auto" /> {/* SVG scales with the word */}
                </span>
              </span>
            </h1>
            <p className="text-xl mb-6 font-montserrat text-center sm:text-left mr-6 md:mt-10">
            Få direktinformation från din hyresvärd, se din energi- och vattenförbrukning i realtid, och gör felanmälningar snabbt och enkelt. Snapp Bostadsapp ger dig full kontroll över ditt boende – allt på ett ställe!
            </p>
            <div className="flex flex-row justify-center sm:justify-start space-x-4 md:mt-10">
              <a href="https://apps.apple.com/se/app/snapp-boendeapp/id6680190341" target="_blank" rel="noopener noreferrer" className="transition duration-300">
                <img src={appstoreImg} alt="Download on App Store" className="h-12" />
              </a>
              <a href="https://play.google.com/store/apps/details?id=com.snapphome.app" target="_blank" rel="noopener noreferrer" className="transition duration-300">
                <img src={androidImg} alt="Get it on Google Play" className="h-12" />
              </a>
            </div>
          </div>
          <div className="hidden sm:block w-1/3">
            <img src={heroImage} alt="Phone Mockup" className="mx-auto sm:ml-auto sm:mr-0 max-w-sm w-full" />
          </div>
        </div>
      </div>
    </section>
  );
};

export default HeroSection;